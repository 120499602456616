import React, { useState } from 'react';
import Modal from 'react-modal';
import Blog1 from '../../widgets/blog/blog1';
import Featureskill from '../../widgets/featurebox/featureskill';
import Herosection7 from '../../widgets/herosection/heroSection7';
import Pricingplan4 from '../../widgets/pricing/pricingplan4';

function Index() {
    const [isOpen, setIsOpen] = useState(false);
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <section>
                <div className="container ps-8 pe-8">
                    <Herosection7 />
                </div>
            </section>
            <div className="page-content">
                <section className="p-0">
                    <div className="container ps-8 pe-8 ">
                        <div className="row justify-content-center text-center">
                            <div className="col-12 col-md-12 col-lg-8 mb-8">
                                <div> <span className="badge badge-primary-soft p-2">
                                    <i className="la la-cubes ic-3x rotation" />
                                </span>
                                    <h2 className="mt-3">Asombrosas caracteristicas de nuestra aplicación</h2>
                                    <p className="lead mb-0">Cada viajero tiene necesidades únicas, por eso te ofrecemos una gama de herramientas personalizables para que diseñes la experiencia perfecta.</p>
                                </div>
                            </div>
                        </div>
                        <Featureskill />
                    </div>
                </section>
                <section>
                    <div className="container ps-8 pe-8">
                        <Pricingplan4 />
                    </div>
                </section>
                <Blog1 />
            </div>
            {isOpen && <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                appElement={document.getElementById('root')}
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 9999,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        position: 'relative',
                        border: 'none',
                        background: 'none',
                        padding: 0,
                        width: '50%',
                        maxHeight: '50%',
                        marginTop: '5rem',
                    },
                }}
            >
            </Modal>}
        </div>
    )
}

export default Index;