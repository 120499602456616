import React, { useEffect } from 'react';
import Pageheading from '../../../widgets/PageHeading';
import Blog1 from '../../../widgets/blog/blog1';
import Blog4 from '../../../widgets/blog/blog4';


const BlogDemo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <section className="position-relative">
                <Pageheading foldername={"Features"} title={"Blog"} />
            </section>
            <div className="page-content">
                <Blog1 />
                <Blog4 />
            </div>
        </div>
    );
}

export default BlogDemo;

