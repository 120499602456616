import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Col, Container, Row } from 'reactstrap';
import PageHeading from '../../../widgets/PageHeading';

const TermCondition = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            {/*hero section start*/}
            <section className="position-relative">
                <PageHeading foldername="Utilities" title="Política de Privacidad" />
            </section>
            {/*hero section end*/}
            {/*body content start*/}
            <div className="page-content">
                {/*terms start*/}
                <section>
                    <Container>
                        <Row>
                            <Col lg="12" md="12">
                                <h4 className="text-primary">1. Introducción</h4>
                                <p className="mb-3">
                                    Bienvenido a Locali App. Respetamos su privacidad y nos comprometemos a protegerla a través de nuestro cumplimiento con esta política.
                                </p>
                                <h4 className="text-primary mt-5">2. Información que recopilamos y permisos que solicitamos</h4>
                                <p className="mb-3">
                                    Nuestra Aplicación solicita los siguientes permisos:
                                    <ul>
                                        <li>
                                            Ubicación: Solicitamos acceso a la ubicación de su dispositivo para proporcionar servicios basados en la localización. Esta información se utiliza únicamente dentro de su dispositivo para mejorar su experiencia de viaje y no se recopila, almacena ni transmite a nuestros servidores o a terceros.
                                        </li>
                                        <li>
                                            Notificaciones: Solicitamos permiso para enviar notificaciones a su dispositivo. Estas notificaciones se utilizan para proporcionarle información relevante sobre su viaje, ofertas especiales, o actualizaciones importantes de la aplicación.
                                        </li>
                                    </ul>
                                </p>
                                <h4 className="text-primary mt-5">3. Cómo utilizamos la información</h4>
                                <p className="mb-3">
                                    a) La información de ubicación se utiliza exclusivamente para:
                                    <ul>
                                        <li>
                                            Mostrar puntos de interés cercanos
                                        </li>
                                        <li>
                                            Proporcionar direcciones y navegación
                                        </li>
                                        <li>
                                            Mejorar las recomendaciones basadas en su ubicación actual
                                        </li>
                                    </ul>

                                    b) Las notificaciones se utilizan para:
                                    <ul>
                                        <li>
                                            Enviar alertas sobre ofertas especiales o eventos cercanos
                                        </li>
                                        <li>
                                            Proporcionar actualizaciones importantes sobre su viaje
                                        </li>
                                        <li>
                                            Informar sobre nuevas características de la aplicación
                                        </li>
                                    </ul>

                                </p>
                                <h4 className="text-primary mt-5">4. Compartición de datos</h4>
                                <p className="mb-3">
                                    No compartimos, vendemos, ni divulgamos de ninguna manera su información de ubicación o sus preferencias de notificación.
                                </p>

                                <h4 className="text-primary mt-5">5. Sus derechos y opciones</h4>
                                <p className="mb-3">
                                    Usted puede:
                                    <ul>
                                        <li>
                                            Activar o desactivar los servicios de ubicación para nuestra Aplicación en cualquier momento a través de la configuración de su dispositivo
                                        </li>
                                        <li>
                                            Activar o desactivar las notificaciones en la configuración de su dispositivo o dentro de la aplicación                                        </li>
                                        <li>
                                            Personalizar sus preferencias de notificación dentro de la aplicación
                                        </li>
                                        <li>
                                            Solicitar información sobre cómo se utilizan sus datos dentro de la Aplicación

                                        </li>
                                    </ul>
                                </p>

                                <h4 className="text-primary mt-5">6. Seguridad de los datos</h4>
                                <p className="mb-3">
                                    Los datos de ubicación se procesan únicamente en su dispositivo. Las preferencias de notificación se almacenan de forma segura y no se comparten con terceros.
                                </p>

                                <h4 className="text-primary mt-5">7. Cambios a esta política</h4>
                                <p className="mb-3">
                                    Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página y, si los cambios son significativos, proporcionaremos un aviso más prominente.
                                </p>

                                <h4 className="text-primary mt-5">8.  Contacto</h4>
                                <p className="mb-3">
                                    Si tiene alguna pregunta sobre esta Política de Privacidad, por favor contáctenos en: info@locali.org                                </p>
                                
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/*terms end*/}
            </div>
            {/*body content end*/}
        </div>
    );
};

export default TermCondition;
