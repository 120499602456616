import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    return (
        <footer className="py-11 bg-primary position-relative" data-bg-img="../../assets/images/bg/03.png">
            <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                </svg>
            </div>
            <Container className=" mt-11">
                <Row className="text-white text-center mt-8">
                    <Col >
                        <Link className="footer-logo text-white h2 mb-0" style={{ textDecoration: 'none' }} to="/">
                            Locali <span className="font-weight-bold">App</span>
                        </Link>
                    </Col>
                    <Col>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-facebook" /></Link>
                        </li>
                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-instagram" /></Link>
                        </li>
                        <li className="list-inline-item"><Link className="text-light ic-2x" to="#"><i className="la la-twitter" /></Link>
                        </li>
                    </ul>
                    </Col>
                </Row>
                <Row className="text-white text-center mt-8">
                    <Col>
                        <hr className="mb-8" />Copyright 2024 por Locali | Todos los derechos reservados
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
