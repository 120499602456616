import React from 'react';
import OwlCarousel from 'react-owl-carousel';

const Clientlogo = ({ logoitems, margintop }) => {
    return (
        <OwlCarousel
            className={`owl-carousel mt-${margintop} no-pb`}
            dotData={false}
            items={logoitems}
            autoplay={true}
            margin={30}
            dots={false}
            loop={true}
        >
            
        </OwlCarousel>
    );
};

export default Clientlogo;
