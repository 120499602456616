export default [
    {
        "menu_title": "Inicio",
        "type": "menu",
        "path": "/",
        "icon": "home",
    },
    {
        "menu_title": "Sobre nosotros",
        "type": "menu",
        "path": "/about-us",
        "icon": "home",
    },
    {
        "menu_title": "Contacto",
        "type": "menu",
        "path": "/contact-us-1",
        "icon": "home",
    },
]
